const isValidParam = (param) => {
    if (!param) return false;
    return true;
};

const sanitizeUrl = (route) => {
    let isSanitized = true;
    let sanitizedUrl = new URL(window.location.href.replace(window.location.search, ''));
    Object.keys(route.query).forEach((x) => {
        if (isValidParam(x) && isValidParam(route.query[x])) {
            sanitizedUrl.searchParams.append(x, route.query[x]);
        } else {
            delete route.query[x];
            isSanitized = false
        }
    });
    if (!isSanitized) {
        window.history.pushState({}, 'checkout', `${sanitizedUrl.protocol}//${sanitizedUrl.host}${sanitizedUrl.pathname}${sanitizedUrl.search}`);
        // window.location.href = `${sanitizedUrl.protocol}//${sanitizedUrl.host}${sanitizedUrl.pathname}${sanitizedUrl.search}`
    }
}

export {
    sanitizeUrl
}