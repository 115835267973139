import axios from 'axios';
import Cookies from 'js-cookie';
import store from '../../store';
import { GreennLogs } from '@/services/resources/GreennLogs.js';
import md5 from 'crypto-js/md5';
import Vue from 'vue';
import {
  fpjsPlugin,
  FpjsVueOptions,
} from '@fingerprintjs/fingerprintjs-pro-vue-v2';

// Adicionando o plugin aqui
Vue.use(fpjsPlugin, {
  loadOptions: {
    apiKey: process.env.VUE_FINGERPRINT_API_KEY,
    endpoint: [
      process.env.VUE_FINGERPRINT_ENDPOINT
    ],
    scriptUrlPattern: [
      `${process.env.VUE_FINGERPRINT_PATH}?apiKey=<apiKey>&version=<version>&loaderVersion=<loaderVersion>`
    ]
  },
});

const standard = axios.create({
  baseURL: process.env.VUE_APP_API_HOST,
  timeout: 100000,
  transformResponse: [
    function (data) {
      return data;
    },
  ],
});

standard.interceptors.request.use(
  async function (config) {

    if (Cookies.get('access_token')) {
      config.headers['Authorization'] = Cookies.get('access_token');
    }
    // Adicionando cabeçalho Access-Control-Allow-Origin globalmente para outros endpoints
    config.headers['Access-Control-Allow-Origin'] = '*';

    // Adicionando header relacionado ao session id do datadog
    const sessionId = GreennLogs.getInternalContext()?.session_id ?? '';
    config.headers['X-Session-ID'] = sessionId;

    if (config.url.includes('/api/payment')) {
      let data_wd = store.state['wd-token-'];

      GreennLogs.logger.info('axiosRequest', {
        'data-wd': data_wd,
        'axiosRequest':config
      });

      config.headers['Controller-Token-'] = store.state['controller-token-'];
      config.headers['RequestRay-Token-'] = store.state['requestray-token-'];
      config.headers['Firewall-Token-'] = store.state['firewall-token-'];
      config.headers['Cache-Token-'] = store.state['cache-token-'];
      config.headers['Trans-Token-'] = store.state['trans-token-'];
      config.headers['Wd-Token-'] = data_wd; 
      config.headers['X-Fingerprint-RID'] = await setVisitorIdOnHeader();
    }

    if (config.url.includes('/checkout/card')) { 
       const apiKey = process.env.VUE_APP_CHECKOUT_GATEWAY_KEY;
       let result = calculateHashWithRandomSaltAndIteration(apiKey);
       const encrypted = result.hash + result.salt + result.iterations;
       config.headers["X-Fingerprint-RID"] = await setVisitorIdOnHeader();
       
       config.headers['x-greenn-gateway'] =  encrypted;
       delete config.headers['Authorization']; // Remover o cabeçalho Authorization

       GreennLogs.logger.info('axiosRequest.card', {
        'axiosRequest': config,
        'extra': { 'fingerprint_request_id': config.headers["X-Fingerprint-RID"] ?? '' }
      });
    }
    return config
  },
  function (error) {
    return Promise.reject(error); 
  }
)

// Função para calcular o hash MD5 com salt e iteração aleatória
  function calculateHashWithRandomSaltAndIteration(text) {
    // Gera um salt aleatório
    const salt = Math.floor(1000 + Math.random() * 9000).toString();
    
    // Gera um número aleatório de iterações entre 1 e 10
    const iterations = Math.floor(1 + Math.random() * 10);

    let textWithSalt = text + salt;
    
    // Realiza a iteração adicionando o salt ao texto várias vezes
    for (let i = 0; i < iterations; i++) {
      textWithSalt = md5(textWithSalt).toString();
    }
    
    // Retorna o hash final, o salt utilizado, e o número de iterações
    return { hash: textWithSalt, salt: salt, iterations: iterations };
  }

  async function setVisitorIdOnHeader(){
    try {
      const visitorData = await Vue.prototype.$fpjs.getVisitorData();
      return visitorData.requestId

    } catch (error) {
      GreennLogs.logger.error("ErrorFingerPrint", {
        name: 'ErrorFingerPrint',
        error: error,
      });
      return false
    }
  }
// standard.interceptors.response.use(..., ...)
export default standard;